const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://tymensegcom.dvgeo.app' : 'http://192.168.100.106:3028',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://tymensegcom.dvgeo.app' : 'http://192.168.100.106:3028',
    api: 'api/',
    apiSocket: 'tymensegcom::1.2',
    nameDir: 'tymensegcom',
    package: 'ec.tymendriver.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyBimoDQO3FucHOFNZ5QqJGzkI79IsSMKlk',
    appName: 'TYMENSEGCOM',
    provider: 'Devjos CIA LTDA',
    colorPrimary: '#EDC500',
    colorDark: '#1A1A1A',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'ec.tymendriver.passenger',
    playStoreDriverId: 'ec.tymendriver.driver',
    appStorePassengerId: '',
    appStoreDriverId: '',
    email: "racedriver-2019@hotmail.com",
};
export default config;
